import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
    <h1>Hello World</h1>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          This is the first attempt to deploy via Netlify.
        </p>
          <form name="contact" netlify>
          <p><label>Name <input type="text" name="name" /></label></p>

          <p><label>Email <input type="email" name="email" /></label></p>

          <p><button type="submit">Send</button></p>
          </form>
      </header>
    </div>
  );
}

export default App;
